import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { Button, Container, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { dropTask, registerGoBackEffect } from "../../actions/taskCore";
import MarkupApi from "../../markupApi";
import TaskFactory from "../Tasks";
import BannerFactory from "./Banners";
import NetworkErrorHandler from "../NetworkErrorHandler/component";
import { goBack } from "connected-react-router";
import styles from "./task-core.module.css";
import useTaskCore from "./useTaskCore";
import axios from "axios";

const { API_URL } = window;

export default function TaskCore() {
  const state = useSelector((state) => state.taskCoreReducer);
  const dispatch = useDispatch();
  const { taskId } = useParams();
  const { searchParams } = new URL(window.location);
  const batch_id = searchParams.get("batch_id");
  const { initTask, saveBatch, skipBatch, updateBatch, loading, error } =
    useTaskCore();
  const currentUser = useSelector((state) => state.userReducer.currentUser);

  const handleHistoryBackButtonClick = () => dispatch(goBack());

  const [batchesCount, setBatchesCount] = useState({ today: 0, total: 0 });

  const getUserSpentTaskTime = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const { data } = await axios.get(
        `${API_URL}/tasks/${taskId}/status/${currentUser.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // нужно получить батчи задания и подсчитать сделанные
      const { done_batches, today_batches } = data.data;
      setBatchesCount({ total: done_batches, today: today_batches });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (error) return;

    initTask();
    getUserSpentTaskTime();
  }, []);

  useEffect(() => {
    if (!batch_id) return;
    updateBatch(batch_id);
  }, [batch_id]);

  useEffect(() => {
    dispatch(registerGoBackEffect(taskId));
  }, [dispatch, taskId]);

  const handleReturn = async () => {
    await MarkupApi.updateBatch(state.batchId, "skipped");
    dispatch(dropTask());
    dispatch(push("/tasks"));
  };

  // TODO: fix tugged banner appearance
  const Banner = BannerFactory.createBanner(error);
  if (Banner !== null) {
    return <Banner />;
  }

  if (error) {
    return <NetworkErrorHandler error={error} />;
  }

  // returned when task type is unknown
  if (state.markupType === null) {
    return null;
  }
  // create custom batch and buttons
  const [Batch, Buttons] = TaskFactory.createTask(state.markupType);

  const saveClassMarkup = (e) => {
    const imageId = state.batchImages[0].id;
    dispatch({
      type: "SET_MARKUP",
      imageId,
      markup: {
        class: e.target.value,
      },
    });
  };

  const saveTextMarkup = (text) => {
    const imageId = state.batchImages[0].id;
    dispatch({
      type: "SET_MARKUP",
      imageId,
      markup: {
        text,
      },
    });
  };

  const handleSave = async (e) => {
    if (state.markupType === "classification") {
      saveClassMarkup(e);
    }
    if (state.markupType === "text") {
      saveTextMarkup(e);
    }
    const successfullySaved = await saveBatch();
    if (successfullySaved) {
      setBatchesCount((prev) => {
        const copy = { ...prev };
        copy["today"] += 1;
        copy["total"] += 1;
        return copy;
      });
    }
  };

  // set visibility for loaded batch
  let cls = styles["batch-container"];

  return (
    <div className="mt-3">
      <Button
        onClick={handleReturn}
        className="mr-5 p-2 float-right"
        variant="outline-primary"
      >
        <FontAwesomeIcon icon={faHome} size="lg" />
      </Button>
      <div style={{ marginLeft: 20 }}>
        <b>Сделано картинок</b>
        <br />
        <span>Cегодня: {batchesCount.today}</span>
        <br />
        <span>За все время: {batchesCount.total}</span>
      </div>
      <Container className="text-center">
        <div>
          <div className={cls}>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <>
                {state.batchImages && (
                  <Batch
                    handleHistoryBackButtonClick={handleHistoryBackButtonClick}
                    {...state}
                  />
                )}
              </>
            )}
          </div>
          <Buttons
            handleHistoryBackButtonClick={handleHistoryBackButtonClick}
            handleSave={handleSave}
            handleSkip={skipBatch}
            disabled={loading}
            {...state}
          />
        </div>
      </Container>
    </div>
  );
}
