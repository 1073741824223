import { handleActions } from "redux-actions";
import {
  splitAssignedTasks,
  splitAssignedUsers,
} from "../components/AdminPanel/AdminPanelMenu/utils";

const getPrevDayDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
};

const defaultState = {
  users: null,
  user: null,
  tasks: null,
  task: null,
  batches: null,
  images: null,
  markupQuery: {
    userId: null,
    taskId: null,
    batchId: null,
    taskType: null,
    date: new Date(),
    useDate: true,
    page: null,
    totalPages: null,
    marked: null,
    classname: null,
  },
  timetrackingQuery: {
    userId: null,
    taskId: null,
    from: getPrevDayDate(),
    to: getPrevDayDate(),
    timeThreshold: null,
    minThreshold: 1,
    maxThreshold: 300,
  },
  timetrackingData: null,
  isFetching: false,
};

const buildItemsUpdateReducer = (itemKey, itemsKey) => {
  const updateItems = (state, action) => {
    const items = action.payload[itemsKey];
    const selectedItem = action.payload[itemKey];
    const newState = {
      ...state,
      isFetching: false,
    };
    newState[itemKey] = selectedItem;
    newState[itemsKey] = items;
    return newState;
  };

  return updateItems;
};

// NOTE: calculates related item
// for example user change in filter may drop selected task
const getRelatedItem = (
  itemId,
  itemCollection,
  relId,
  relCollection,
  splitFn
) => {
  const item = itemCollection.filter((i) => i.id === itemId)[0];
  let relItem = null;
  if (relId !== null) {
    relItem = relCollection.filter((i) => i.id === relId)[0];
  }

  const { dissociatedItems } = splitFn(relCollection, item);
  if (relItem !== null && relItem in dissociatedItems) {
    relItem = null;
  }
  return relItem;
};

const validateRange = (from, to) => to - from >= 0;

export default handleActions(
  {
    LOCK_ADMIN_FETCH: (state, action) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    UNLOCK_ADMIN_FETCH: (state, action) => {
      return {
        ...state,
        isFetching: false,
      };
    },
    UPDATE_USERS: buildItemsUpdateReducer("user", "users"),
    UPDATE_TASKS: buildItemsUpdateReducer("task", "tasks"),
    SET_USER: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    SET_TASK: (state, action) => {
      return {
        ...state,
        task: action.payload,
      };
    },
    ASSIGN_USER: (state, action) => {
      const { userId, taskId } = action.payload;
      let { users, tasks } = state;

      const user = users.filter((u) => u.id === userId)[0];
      const task = tasks.filter((t) => t.id === taskId)[0];

      user.tasks.push(task);
      user.tasks.sort((a, b) => a.id - b.id);

      return {
        ...state,
        users,
      };
    },
    DISSOCIATE_USER: (state, action) => {
      const { userId, taskId } = action.payload;
      let { users } = state;

      const user = users.filter((u) => u.id === userId)[0];
      user.tasks = user.tasks.filter((t) => t.id !== taskId);

      return {
        ...state,
        users,
      };
    },
    CHANGE_QUERY_USER_ID: (state, action) => {
      const userId = action.payload;
      const taskId = state.markupQuery.taskId;

      const task = getRelatedItem(
        userId,
        state.users,
        taskId,
        state.tasks,
        splitAssignedTasks
      );

      const markupQuery = Object.assign({}, state.markupQuery);
      markupQuery.taskId = task !== null ? task.id : null;
      markupQuery.userId = userId;
      markupQuery.batchId = null;
      markupQuery.page = null;
      markupQuery.marked = null;
      markupQuery.classname = null;

      return {
        ...state,
        images: null,
        markupQuery,
      };
    },
    CHANGE_QUERY_TASK_ID: (state, action) => {
      const taskId = action.payload;
      const userId = state.markupQuery.userId;

      const user = getRelatedItem(
        taskId,
        state.tasks,
        userId,
        state.users,
        splitAssignedUsers
      );

      const markupQuery = Object.assign({}, state.markupQuery);
      markupQuery.taskId = taskId;
      markupQuery.userId = user !== null ? user.id : null;
      markupQuery.batchId = null;
      markupQuery.page = null;
      markupQuery.marked = null;
      markupQuery.classname = null;

      return {
        ...state,
        images: null,
        markupQuery,
      };
    },
    CHANGE_QUERY_BATCH_MARKED_STATUS: (state, action) => {
      const marked = action.payload;

      const markupQuery = Object.assign({}, state.markupQuery);
      markupQuery.batchId = null;
      markupQuery.page = null;
      markupQuery.marked = marked;
      markupQuery.classname = null;

      return {
        ...state,
        images: null,
        markupQuery,
      };
    },
    CHANGE_QUERY_BATCH_CLASS: (state, action) => {
      const classname = action.payload;

      const markupQuery = Object.assign({}, state.markupQuery);
      markupQuery.batchId = null;
      markupQuery.page = null;
      markupQuery.marked = null;
      markupQuery.classname = classname;

      return {
        ...state,
        images: null,
        markupQuery,
      };
    },
    SET_PAGINATION_DATA: (state, action) => {
      const markupQuery = Object.assign({}, state.markupQuery);
      markupQuery.page = action.payload.page;
      markupQuery.totalPages = action.payload.totalPages;
      markupQuery.batchId =
        "batchId" in action.payload ? action.payload.batchId : null;

      return {
        ...state,
        markupQuery,
        images: null,
        batches: action.payload.batches,
      };
    },
    CHANGE_QUERY_DATE: (state, action) => {
      const markupQuery = Object.assign({}, state.markupQuery);
      markupQuery.date = action.payload;
      markupQuery.batchId = null;
      markupQuery.page = null;

      return {
        ...state,
        images: null,
        markupQuery,
      };
    },
    TOGGLE_DATE_USAGE: (state, action) => {
      const markupQuery = Object.assign({}, state.markupQuery);
      markupQuery.useDate = action.payload;
      markupQuery.batchId = null;
      markupQuery.page = null;

      return {
        ...state,
        images: null,
        markupQuery,
      };
    },
    CHANGE_QUERY_BATCH_ID: (state, action) => {
      const markupQuery = Object.assign({}, state.markupQuery);
      markupQuery.batchId = action.payload;

      return {
        ...state,
        images: null,
        markupQuery,
      };
    },

    CHANGE_TIMETRACKING_FROM_DATE: (state, action) => {
      const from = action.payload;
      const to = state.timetrackingQuery.to;

      if (to !== null && !validateRange(from, to)) {
        return {
          ...state,
        };
      }

      const timetrackingQuery = Object.assign({}, state.timetrackingQuery);
      timetrackingQuery.from = from;
      return {
        ...state,
        timetrackingQuery,
      };
    },
    CHANGE_TIMETRACKING_TO_DATE: (state, action) => {
      const from = state.timetrackingQuery.from;
      const to = action.payload;

      if (from !== null && !validateRange(from, to)) {
        return {
          ...state,
        };
      }

      const timetrackingQuery = Object.assign({}, state.timetrackingQuery);
      timetrackingQuery.to = to;
      return {
        ...state,
        timetrackingQuery,
      };
    },
    DROP_QUERY: (state, action) => {
      const markupQuery = {
        userId: null,
        taskId: null,
        batchId: null,
        taskType: null,
        date: new Date(),
        useDate: true,
        page: null,
        totalPages: null,
        marked: null,
        classname: null,
      };

      return {
        ...state,
        images: null,
        markupQuery,
      };
    },
    DROP_TIMETRACKING_QUERY: (state, action) => {
      const timetrackingQuery = {
        userId: null,
        taskId: null,
        from: getPrevDayDate(),
        to: getPrevDayDate(),
        timeThreshold: null,
        minThreshold: 1,
        maxThreshold: 300,
      };

      return {
        ...state,
        timetrackingData: null,
        timetrackingQuery,
      };
    },
    ADD_BATCH_IMAGES: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    SET_TIMETRACKING_DATA: (state, action) => {
      return {
        ...state,
        timetrackingData: action.payload,
      };
    },
    UPDATE_ADMIN_MARKUP: (state, action) => {
      const images = state.images.slice();
      const image = images.filter(
        (img) => img.id === action.payload.imageId
      )[0];
      image.markup = action.payload.markup;
      return {
        ...state,
        images,
      };
    },
  },
  defaultState
);
